import {
  get,
  post
}
  from '../common/axiosHttp.js'

// 获取雨情设备
export const getRainList = params => get('/rainfallRegime/getRainList', params);

// 雨情添加
export const rainAdd = params => post('/rainfallRegime/add', params);

// 雨情修改
export const rainUpdate = params => post('/rainfallRegime/update', params);

export const rainDelete = params => post('/rainfallRegime/delete', params);




