<!--  -->
<template>
  <div class="main_box">
    <div class="nav_menu">
      <div class="menu_left">
        <el-input
          placeholder="请输入设备名称"
          class="search_input"
          v-model="value"
        ></el-input>
        <el-button
          style="margin-left:calc(80/1920*100vw)"
          type="primary"
          @click="handleSearch(0)"
          >查询</el-button
        >
        <el-button type="info" @click="handleSearch(1)">重置</el-button>
      </div>
      <div class="menu_right">
        <el-button type="success" @click="handleRain('add')"
          >新增设备</el-button
        >
      </div>
    </div>
    <div>
      <el-table
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }"
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column type="index" prop="sid" label="序号" width="95">
        </el-table-column>
        <el-table-column prop="rainName" label="设备名称"> </el-table-column>

        <el-table-column
          prop="rainModal"
          label="设备型号"
          :show-overflow-tooltip="true"
        >
        </el-table-column>

        <el-table-column prop="capacity" label="容量"> </el-table-column>
        <el-table-column
          prop="deviceAddress"
          label="设备地址码"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="rainRunStatus" label="运行状态">
        </el-table-column>
        <el-table-column
          prop="dataTime"
          label="最后回传时间"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="departmentName"
          label="设备经纬度"
          :show-overflow-tooltip="true"
        >
          <template scope="scope">
            <span> {{ scope.row.lng }},{{ scope.row.lat }}</span>
          </template>
        </el-table-column>
        <el-table-column width="250" label="操作">
          <template slot-scope="scope">
            <el-button class="edit" @click="handleRain('edit', scope.row)"
              >编辑</el-button
            >
            <el-button class="delete" @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      background
      layout="total, prev, pager, next, jumper"
      :total="pagination.total"
      @current-change="handleCurrent"
      :current-page="pagination.page"
    >
    </el-pagination>
    <el-dialog
      class="border-r-8 role-dialog"
      :visible.sync="addShow"
      width="calc(388/1920*100vw)"
      @close="rainClose"
    >
      <div slot="title" class="dialogTitle t-x font-s-18">
        雨情设备管理-{{ title }}
      </div>
      <div class="contentAdd" style="margin-bottom: 10px">
        <el-form :model="rainInfo" ref="ruleForm" :rules="ruleForm">
          <el-form-item prop="rainName" style="margin-top:calc(25/1080*100vh)">
            <span class="label_r">设备名称</span>
            <el-input
              v-model="rainInfo.rainName"
              placeholder="请输入设备名称"
              maxlength="10"
            ></el-input>
          </el-form-item>
          <el-form-item prop="rainModal" style="margin-top:calc(30/1080*100vh)">
            <span class="label_r">设备型号</span>
            <el-input
              placeholder="请输入设备型号"
              v-model="rainInfo.rainModal"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="deviceAddress"
            style="margin-top:calc(30/1080*100vh)"
          >
            <span class="label_r">设备地址码</span>
            <el-input
              placeholder="请输入设备地址码"
              v-model="rainInfo.deviceAddress"
              maxlength="50"
              :readonly="this.title == '新增' ? false : true"
            ></el-input>
          </el-form-item>
          <el-form-item style="margin-top:calc(30/1080*100vh)">
            <span class="label_r">经纬度选点</span>
            <LocationSelection
              :key="addShow"
              area="东营区"
              :location="rainInfo.coordinate"
              @locationSelection="locationSelection"
              style="width: calc(244 / 1920 * 100vw); height: calc(40/1080*100vh);"
            />
          </el-form-item>
          <el-form-item
            prop="coordinate"
            class="mgb"
            style="margin-top:calc(30/1080*100vh)"
          >
            <span class="label_r">设备经纬度</span>
            <el-input
              placeholder="请输入经纬度(经度,纬度)"
              v-model="rainInfo.coordinate"
              :readonly="true"
              maxlength="25"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="capacity"
            class="mgb"
            style="margin-top:calc(30/1080*100vh)"
          >
            <span class="label_r">容量</span>
            <el-input
              placeholder="请输入容量"
              onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
              v-model="rainInfo.capacity"
              maxlength="2"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleShow" class="guanBi">关闭</el-button>
        <el-button
          @click="handleSave('ruleForm')"
          type="primary"
          v-if="this.title == '新增' ? true : false"
          style="margin-left:calc(30/1920*100vw)"
          >保存</el-button
        >
        <el-button
          @click="handleEdit('ruleForm')"
          style="margin-left:calc(30/1920*100vw)"
          type="primary"
          v-else
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getNowDayFn, getNowTime } from "../../../utils/methods";
import { isValidCount, guardFall } from "./validate";
import {
  getRainList,
  rainAdd,
  rainUpdate,
  rainDelete
} from "../../../api/RainMessage";
import LocationSelection from "../../../components/LocationSelection/index.vue";

var ValidCount = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入容量"));
  } else if (!isValidCount(value)) {
    callback(new Error("请输入正确的容量"));
  } else {
    callback();
  }
};
var isGuardFall = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入设备地址码"));
  } else if (!guardFall(value)) {
    callback(new Error("请输入正确的设备地址码"));
  } else {
    callback();
  }
};
export default {
  components: {
    LocationSelection
  },
  data() {
    return {
      loading: false,
      addList: [],
      tableData: [],
      pagination: {
        // 分页
        size: 10,
        page: 1,
        total: 0
      },
      value: "",
      rainInfo: {},
      addShow: false,
      title: "",
      typeList: [
        // 设备类型
        {
          label: "球机",
          value: 0
        },
        {
          label: "枪机",
          value: 1
        }
      ],
      stationList: [], // 站点列表
      ruleForm: {
        rainName: [
          { required: true, message: "请输入设备名称", trigger: "blur" }
        ],
        rainModal: [
          { required: true, message: "请输入设备型号", trigger: "blur" }
        ],
        deviceAddress: [
          { required: true, message: "请输入设备地址码", trigger: "blur" },
          {
            required: true,
            message: "请输入正确的设备地址码",
            trigger: "change",
            validator: isGuardFall
          }
        ],
        capacity: [
          { required: true, message: "请输入容量", trigger: "blur" },
          {
            required: true,
            message: "请输入正确的容量",
            trigger: "blur",
            validator: ValidCount
          }
        ]
      },
      type: false,
      check: false
    };
  },
  computed: {},

  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    getList() {
      this.loading = true;
      getRainList({
        page: this.pagination.page,
        size: this.pagination.size
      }).then(res => {
        console.log(res);
        this.tableData = res.data.list;
        this.pagination.total = res.data.total;
        this.tableData.forEach(item => {
          if (item.rainRunStatus == 0) {
            item.rainRunStatus = "正常";
          }
          if (item.rainRunStatus == 1) {
            item.rainRunStatus = "离线";
          }
          if (item.rainRunStatus == 2) {
            item.rainRunStatus = "下雨";
          }
        });
        this.loading = false;
      });
    },
    // 分页切换
    handleCurrent(page) {
      this.pagination.page = page;
      this.getList();
    },
    // 搜索
    handleSearch(type) {
      if (type == 0) {
        getRainList({
          rainName: this.value,
          page: this.pagination.page,
          size: this.pagination.size
        }).then(res => {
          console.log(res);
          this.tableData = res.data.list;
          this.pagination.total = res.data.total;
          this.tableData.forEach(item => {
            if (item.rainRunStatus == 0) {
              item.rainRunStatus = "正常";
            }
            if (item.rainRunStatus == 1) {
              item.rainRunStatus = "离线";
            }
            if (item.rainRunStatus == 2) {
              item.rainRunStatus = "下雨";
            }
          });
        });
      } else {
        this.value = "";
        this.getList();
      }
    },
    // 新增编辑
    handleRain(type, row) {
      if (type == "add") {
        this.title = "新增";
        // this.rainInfo = {};
        this.addShow = true;
      } else {
        this.title = "编辑";
        this.addShow = true;
        console.log(row);
        this.$set(this.rainInfo, "id", row.id);
        this.$set(this.rainInfo, "rainName", row.rainName);
        this.$set(this.rainInfo, "rainModal", row.rainModal);
        this.$set(this.rainInfo, "capacity", row.capacity);
        this.$set(this.rainInfo, "deviceAddress", row.deviceAddress);
        this.$set(this.rainInfo, "coordinate", row.lng + "," + row.lat);
      }
    },
    // 编辑保存
    handleEdit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let arr = [];
          arr = this.rainInfo.coordinate.split(",");
          this.rainInfo.updateEmp = window.localStorage.getItem("userName");
          // 雨情新增
          rainUpdate({
            id: this.rainInfo.id,
            deviceAddress: this.rainInfo.deviceAddress,
            rainName: this.rainInfo.rainName,
            rainModal: this.rainInfo.rainModal,
            longitude: arr[0],
            latitude: arr[1],
            capacity: parseInt(this.rainInfo.capacity),
            updateEmp: this.rainInfo.createEmp
          })
            .then(res => {
              this.$message.success(res.message);
              this.addShow = false;
              this.getList();
            })
            .catch(err => {
              this.$message.error(err.message);
            });
        } else {
          return false;
        }
      });
    },
    // 新增  保存
    handleSave(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.rainInfo.createEmp = window.localStorage.getItem("userName");
          // 雨情新增
          rainAdd({
            deviceAddress: this.rainInfo.deviceAddress,
            rainName: this.rainInfo.rainName,
            rainModal: this.rainInfo.rainModal,
            longitude: this.addList[0],
            latitude: this.addList[1],
            capacity: parseInt(this.rainInfo.capacity),
            createEmp: this.rainInfo.createEmp
          })
            .then(res => {
              if (res.code == 200) {
                this.$message.success(res.message);
                this.addShow = false;
                this.getList();
              }
            })
            .catch(err => {
              this.$message.error(err.message);
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    handleShow() {
      this.addShow = false;
      this.rainInfo = {};
      this.$refs["ruleForm"].resetFields();
    },
    // 删除
    handleDelete(item) {
      let { id } = item;
      this.$confirm("此操作将永久删除该雨情设备，确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          rainDelete({ id: id })
            .then(res => {
              if (res.code == 200) {
                this.$message({
                  message: res.message,
                  type: "success"
                });
                this.pagination.size = 10;
                if (this.pagination.total % 10 == 1) {
                  this.pagination.page--;
                }
              } else {
                this.$message({
                  message: res.message,
                  type: "error"
                });
              }
            })
            .catch(err => {})
            .finally(() => {
              this.getList();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 经纬度选点
    locationSelection(e) {
      if (e.point) {
        this.$set(this.rainInfo, "coordinate", `${e.point.lng},${e.point.lat}`);
        this.addList = this.rainInfo.coordinate.split(",");
      }
    },
    rainClose() {
      this.rainInfo = {};
      this.$refs["ruleForm"].resetFields();
    }
  }
};
</script>
<style lang='scss' scoped>
::v-deep .el-dialog {
  background: #4073b6;
  border-radius: 16px;
  width: calc(388 / 1920 * 100vw) !important;
  .el-dialog__header {
    background: #fff;
    margin-top: 4px;
    border-radius: 16px 16px 0 0;
  }
  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: calc(15 / 1080 * 100vh) 0;
    .el-button:nth-child(1):hover {
      background: #eaeaea;
      border: 1px #eaeaea solid;
      color: #313131;
    }
  }
  // 弹框主体
  .el-dialog__body {
    background: #fff;
    height: calc(395 / 1080 * 100vh);
    // 菜单选框
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      label {
        width: 34% !important;
        height: calc(25 / 1080 * 100vh);
        margin-right: 0;
      }
    }
  }
  .el-input__inner {
    border: none;
    border-radius: 4px;
    background: #f3f4f8;
  }
  .label_r {
    margin-left: 20px !important;
    width: calc(88 / 1920 * 100vw);
    text-align: left;
    color: #666 !important;
  }
}
.el-input,
.el-textarea,
.el-select {
  width: calc(244 / 1920 * 100vw);
}
.images {
  height: calc(60 / 1080 * 100vh);
  i {
    width: calc(59 / 1920 * 100vw);
    height: calc(59 / 1080 * 100vh);
    border: 1px solid #585858;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

::v-deep .el-form-item__content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: flex-start;
}

::v-deep .el-input.search_input {
  width: calc(175 / 1920 * 100vw);
}

::v-deep .menu_left {
  .el-input__inner {
    width: calc(270 / 1920 * 100vw);
  }
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid transparent;
  border-color: rgba(102, 102, 102, 0.1);
}
</style>
