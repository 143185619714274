// 负责人电话验证
export function isValidCount(str) {
  const reg = /^\d$|^[0-4][0-9]$|50/gi;
  return reg.test(str);
}

export function guardFall(str) {
  const reg = /^SR\d{6}$/;
  return reg.test(str);
}
